<template>
  <div class="align">

    <div class="grid align__item">

      <div class="register">

        <span class="brand-logo">
          <b-img
            :src="appLogoImage"
            alt="logo"
            style="border: 5px solid orange; margin: 25px;"
          />
        </span>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  class="input"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  :placeholder="`user@${webAddress}`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password" />
                <b-link
                  :to="{name:'auth-forgot-password'}"
                  style="margin-bottom: 5px;"
                >
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="input form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
                style="color: red; text-align: left;"
                class="custom-control-secondary"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="secondary"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <br>

        <b-card-text class="text-center mt-2">
          <span>New? </span>
          <b-link :to="{name:'auth-register'}">
            <span>&nbsp;Create an account</span>
          </b-link>
        </b-card-text>

        <!-- divider -->
        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'home'}">
            <span>Visit our Site</span>
          </b-link>
        </b-card-text>

      </div>

    </div>

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BImg, BLink, BCardText, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BForm, BButton, // BRow, BCol, BCardTitle, VBTooltip,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { $themeConfig } from '@themeConfig'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  // directives: {
  //   'b-tooltip': VBTooltip,
  // },

  components: {
    // BRow,
    // BCol,
    BLink,
    BCardText,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [togglePasswordVisibility],

  setup() {
    // App Name
    const {
      appName,
      appNameSecond,
      webAddress,
      appLogoImage,
    } = $themeConfig.app

    return {
      appName,
      appNameSecond,
      webAddress,
      appLogoImage,
    }
  },

  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },

  computed: {

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              // console.log('\n\n\n######## LOGIN ###########################################################')
              const { userData } = response.data
              // console.log('# User Data')
              // console.log(userData)

              // console.log('# Set Access and Refresh Tokens')
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)

              // userData.ability = {
              //   action: 'manage',
              //   subject: 'all',
              // }

              // console.log('# Update localStorage')
              localStorage.setItem('userData', JSON.stringify(userData))

              // console.log('# Current ABILITY')
              // console.log(this.$ability)
              // console.log('# UserData ABILITY')
              // console.log(userData.ability)
              // console.log('# UserData RULES')
              // console.log(userData.rules)
              // console.log('# Response DATA RULES')
              // console.log(response.data.rules)
              // console.log('# Response BODY RULES')
              // console.log(response.body.rules)
              // this.$ability.update(userData.ability)
              this.$ability.update(response.data.rules)

              // console.log('# Updated ABILITY')
              // console.log(this.$ability)

              // try {
              //   this.$ability.update(userData.ability)
              // } catch (err) {
              //   console.log('\n\n### CAUGHT $ability ERROR\n\n')
              // }

              // this.$ability.update(
              //   {
              //     action: 'manage',
              //     subject: 'all',
              //   },
              // )

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // qthis.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // console.log('replace the route based on userData.role')
              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  })
                })
            })
            .catch(error => {
              // console.log('login.vue - catch error')
              // console.log(error)
              this.$refs.loginForm.setErrors(error.response.data.error)
              // console.log('login.vue - catch error end')
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">

@import '@core/scss/vue/pages/page-auth.scss';
@import '~@core/scss/base/bootstrap-extended/_variables';

$base-bgcolor: $primary;
$base-color: $secondary;
$base-font-weight: 400;
$base-font-size: 1rem;
$base-line-height: 1.5;
$base-font-family: Helvetica Neue;
$base-font-family-fallback: sans-serif;

.align {
  background-color: $base-bgcolor;
  color: $base-color;
  min-height: 100%;
  height: 100vh;

  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: $base-bgcolor;
  color: $base-color;
  margin: 0;
  min-height: 100%;
  &__item {
    &--start {
      align-self: flex-start;
    }

    &--end {
      align-self: flex-end;
    }
  }
}

.site {
  &__main {
  }

  &__logo {
    margin-bottom: 2rem;
  }
}

$input-placeholder-color: rgb(219, 219, 219);

.input {
  border: 0;
  font: inherit;

  &::placeholder {
    color: $input-placeholder-color;
  }
}

.input-group-text {
  border: 0;
}

.form {
  &__field {
    margin-bottom: 1rem;
  }

  .input {
    outline: 0;
    padding: .5rem 1rem;

    &[type="email"],
    &[type="password"] {
      width: 100%;
    }
  }
}

$grid-max-width: 25rem;
$grid-width: 100%;

.grid {
  margin: 0 auto;
  max-width: $grid-max-width;
  width: $grid-width;
}

h2 {
  font-size: 2.75rem;
  font-weight: 100;
  margin: 0 0 1rem;
  text-transform: uppercase;
}

svg {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

$link-color: #fff;

a {
  color: $link-color
}

.custom-control-label[for=remember-me] {
  color: white;
}

.register {
  box-shadow: 0 0 250px #000;
  text-align: center;
  padding: 4rem 2rem;

  background-color: $base-bgcolor;
  color: $base-color;

  input {
    border: 0;
    // background-color: transparent;
    text-align: center;

    &[type="email"],
    &[type="password"] {
      background-repeat: no-repeat;
      background-size: 1.5rem;
      background-position: 1rem 50%;
    }

    &[type="email"] {
      background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#242c37"><path d="M256.017 273.436l-205.17-170.029h410.904l-205.734 170.029zm-.034 55.462l-205.983-170.654v250.349h412v-249.94l-206.017 170.245z"/></svg>');
    }

    &[type="password"] {
      background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#242c37"><path d="M195.334 223.333h-50v-62.666c0-61.022 49.645-110.667 110.666-110.667 61.022 0 110.667 49.645 110.667 110.667v62.666h-50v-62.666c0-33.452-27.215-60.667-60.667-60.667-33.451 0-60.666 27.215-60.666 60.667v62.666zm208.666 30v208.667h-296v-208.667h296zm-121 87.667c0-14.912-12.088-27-27-27s-27 12.088-27 27c0 7.811 3.317 14.844 8.619 19.773 4.385 4.075 6.881 9.8 6.881 15.785v22.942h23v-22.941c0-5.989 2.494-11.708 6.881-15.785 5.302-4.93 8.619-11.963 8.619-19.774z"/></svg>');
    }

    &[type="submit"] {
      background-image: linear-gradient(160deg, #8a77f5 0%, #0a009c 100%);
      color: #fff;
      margin-bottom: 6rem;
      width: 100%;
    }
  }
}
</style>
